/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/owlcarousel-pre@1.3.3/owl-carousel/owl.transitions.min.css
 * - /npm/owlcarousel-pre@1.3.3/owl-carousel/owl.theme.min.css
 * - /npm/owlcarousel-pre@1.3.3/owl-carousel/owl.carousel.min.css
 * - /npm/animate.css@3.7.2/animate.min.css
 * - /npm/magic.css@1.1.0/1.1.0/magic.min.css
 * - /gh/juliyvchirkov/utils.css@2.0.0-rc2/dist/indent.slim.min.css
 * - /gh/juliyvchirkov/utils.css@2.0.0-rc2/dist/dim.min.css
 * - /gh/juliyvchirkov/utils.css@2.0.0-rc2/dist/text.min.css
 * - /gh/juliyvchirkov/utils.css@2.0.0-rc2/dist/dim.min.css
 * - /gh/juliyvchirkov/utils.css@2.0.0-rc2/dist/anim.min.css
 * - /npm/nouislider@15.8.1/dist/nouislider.min.css
 * - /npm/tippy.js@6.3.7/dist/tippy.css
 * - /npm/tippy.js@6.3.7/dist/backdrop.css
 * - /npm/tippy.js@6.3.7/dist/border.css
 * - /npm/tippy.js@6.3.7/dist/svg-arrow.css
 * - /npm/tippy.js@6.3.7/themes/light.css
 * - /npm/tippy.js@6.3.7/themes/light-border.css
 * - /npm/tippy.js@6.3.7/themes/material.css
 * - /npm/tippy.js@6.3.7/themes/translucent.css
 * - /npm/tippy.js@6.3.7/animations/perspective.css
 * - /npm/tippy.js@6.3.7/animations/perspective-extreme.css
 * - /npm/tippy.js@6.3.7/animations/perspective-subtle.css
 * - /npm/tippy.js@6.3.7/animations/scale.css
 * - /npm/tippy.js@6.3.7/animations/scale-extreme.css
 * - /npm/tippy.js@6.3.7/animations/scale-subtle.css
 * - /npm/tippy.js@6.3.7/animations/shift-toward-subtle.css
 * - /npm/tippy.js@6.3.7/animations/shift-toward-extreme.css
 * - /npm/tippy.js@6.3.7/animations/shift-toward.css
 * - /npm/tippy.js@6.3.7/animations/shift-away-subtle.css
 * - /npm/tippy.js@6.3.7/animations/shift-away-extreme.css
 * - /npm/tippy.js@6.3.7/animations/shift-away.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
